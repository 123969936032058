import { graphql, Link, navigate } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Label,
    SemanticICONS,
} from 'semantic-ui-react';
import Layout from '../../components/Layout';

interface PageProps {
    data: {
        markdownRemark: {
            excerpt: string;
            fields: {
                readingTime: {
                    text: string;
                };
            };
            frontmatter: {
                title: string;
                draft?: boolean;
                cover?: {
                    childImageSharp: { fluid: { src: string } };
                };
                tags?: Array<string>;
                categories?: Array<string>;
                date?: string;
            };
            html: string;
        };
        site: {
            siteMetadata: {
                title: string;
            };
        };
    };
    pageContext: {
        previous: any;
        next: any;
    };
}

interface LinkListProps {
    type: string;
    icon: SemanticICONS;
    items: Array<string> | undefined;
}

const LinkList = ({ type, icon, items }: LinkListProps) => {
    if (!items) {
        return <div />;
    }
    const lastIdx = items.length - 1;
    return (
        <Label>
            <Icon name={icon} />
            {items.map((item, idx) => (
                <span key={idx}>
                    <Link to={`/blog/${type}/${item}/`}>{item}</Link>
                    {idx !== lastIdx ? ' • ' : ''}
                </span>
            ))}
        </Label>
    );
};

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt
            html
            fields {
                slug
                readingTime {
                    text
                }
            }
            frontmatter {
                title
                draft
                date(formatString: "MMMM DD, YYYY")
                tags
                categories
            }
        }
    }
`;

export default ({ data, pageContext: { previous, next } }: PageProps) => {
    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = post.excerpt;
    const { tags, categories, date, title } = post.frontmatter;
    return (
        <Layout>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                meta={[{ name: 'description', content: siteDescription }]}
                title={`${siteTitle} | ${title}`}
            />
            <Container text>
                <Header size="huge">{title}</Header>
                <Header sub>
                    <span>
                        <Label content={date} icon="calendar" />
                    </span>
                    <span>
                        <Label
                            content={post.fields.readingTime.text}
                            icon="clock"
                        />
                    </span>
                    <LinkList
                        type="categories"
                        icon="folder"
                        items={categories}
                    />
                    <LinkList type="tags" icon="tag" items={tags} />
                </Header>
                <Divider />
                <Container style={{ marginTop: '2em' }}>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </Container>
            </Container>
            <Container text style={{ marginTop: '2em', marginBottom: '2em' }}>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            {next == 'enabled' && (
                                <Button
                                    onClick={() => navigate(next.fields.slug)}
                                    content="Previous"
                                    icon="left arrow"
                                    labelPosition="left"
                                />
                            )}
                        </Grid.Column>
                        <Grid.Column textAlign="right">
                            {previous == 'enabled' && (
                                <Button
                                    onClick={() =>
                                        navigate(previous.fields.slug)
                                    }
                                    content="Next"
                                    icon="right arrow"
                                    labelPosition="right"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Layout>
    );
};
